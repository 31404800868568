/* eslint-disable */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen'; 
import SingleView from 'src/pages/dashboard/Booking/SingleView';
import BookingHistory from 'src/pages/dashboard/Booking/BookingHistory';
import SSingleView from 'src/pages/dashboard/SlotBooking/SingleView';
import SBookingHistory from 'src/pages/dashboard/SlotBooking/BookingHistory';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin']}>
          <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAnalytics /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },

        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
   
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: ( <RoleBasedGuard accessibleRoles={['admin']}><CategoryCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit', element: <CategoryCreate /> },
       
          ],
        },

        {
          path: 'store',
          children: [
            { element: <Navigate to="/dashboard/store/list" replace />, index: true },
            { path: 'list', element: <StoreList /> },
            { path: 'new', element: ( <RoleBasedGuard accessibleRoles={['admin']}><StoreCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit', element: <StoreCreate /> },
       
          ],
        },
        {
          path: 'offer',
          children: [
            { element: <Navigate to="/dashboard/offer/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <OfferList /> },
            { path: ':name/:storeid/add', element: ( <RoleBasedGuard accessibleRoles={['admin']}><OfferCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <OfferCreate /> },
       
          ],
        },
        {
          path: 'personnel',
          children: [
            { element: <Navigate to="/dashboard/personnel/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <PersonnalList /> },
            { path: ':name/:storeid/add', element: ( <RoleBasedGuard accessibleRoles={['admin']}><PersonnalCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <PersonnalCreate /> },
       
          ],
        },
        {
          path: 'department',
          children: [
            { element: <Navigate to="/dashboard/department/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <DepartmentList /> },
            { path: ':name/:storeid/add', element: ( <RoleBasedGuard accessibleRoles={['admin']}><DepartmentCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <DepartmentCreate /> },
       
          ],
        },
        {
          path: 'store-admin',
          children: [
            { element: <Navigate to="/dashboard/offer/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <OfferList /> },
            { path: ':name/:storeid/:email/add', element: ( <RoleBasedGuard accessibleRoles={['admin']}><StoreAdminCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <OfferCreate /> },
       
          ],
        },

        {
          path: 'service',
          children: [
            { element: <Navigate to="/dashboard/service/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <ServiceList /> },
            { path: ':name/:storeid/add', element: ( <RoleBasedGuard accessibleRoles={['admin']}><ServiceCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <ServiceCreate /> },
       
          ],
        },
        {
          path: 'table',
          children: [
            { element: <Navigate to="/dashboard/table/list" replace />, index: true },
            { path: ':name/:storeid/addtable', element: ( <RoleBasedGuard accessibleRoles={['admin']}><TableCreate /></RoleBasedGuard>) },
            { path: ':name/:storeid/listtable', element: ( <RoleBasedGuard accessibleRoles={['admin']}><TableList /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <BookingCreate /> },
       
          ],
        },
        {
          path: 'tablebooking',
          children: [
            { element: <Navigate to="/dashboard/tablebooking/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <BookingList /> },
            { path: ':name/:storeid/detaillist/:id', element: <SingleView /> },
            { path: ':name/:storeid/bookinghistory', element: <BookingHistory /> },
            { path: ':name/:storeid/addbooking', element: ( <RoleBasedGuard accessibleRoles={['admin']}><BookingCreate /></RoleBasedGuard>) },
            { path: ':name/:storeid/addslot', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SlotCreate /></RoleBasedGuard>) },
            { path: ':name/:storeid/listslotcalendar', element: ( <RoleBasedGuard accessibleRoles={['admin']}><ListSlotCalendar /></RoleBasedGuard>) },
            { path: ':name/:storeid/listslot', element: ( <RoleBasedGuard accessibleRoles={['admin']}><ListSlot /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <BookingCreate /> },
          ],
        },
        {
          path: 'slotbooking',
          children: [
            { element: <Navigate to="/dashboard/booking/list" replace />, index: true },
            { path: ':name/:storeid/list', element: <SBookingList /> },
            { path: ':name/:storeid/detaillist/:id', element: <SSingleView /> },
            { path: ':name/:storeid/bookinghistory', element: <SBookingHistory /> },
            { path: ':name/:storeid/addbooking', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SBookingCreate/></RoleBasedGuard>) },
            { path: ':name/:storeid/addslot', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SSlotCreate /></RoleBasedGuard>) },
            // { path: ':name/:id/edit', element: <StoreCreate /> },
            { path: ':name/:storeid/:singleslot/edit', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SSlotCreate /></RoleBasedGuard>) },

            { path: ':name/:storeid/listslot', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SListSlotCalendar /></RoleBasedGuard>) },
            { path: ':name/:storeid/other', element: ( <RoleBasedGuard accessibleRoles={['admin']}><SListSlot /></RoleBasedGuard>) },
            { path: ':name/:id/edit/:storeid', element: <BookingCreate /> },
          ],
        },



        {
          path: 'service-type',
          children: [
            { element: <Navigate to="/dashboard/service-type/list" replace />, index: true },
   
            { path: 'list', element: <ServiceTypeList /> },
            { path: 'new', element: ( <RoleBasedGuard accessibleRoles={['admin']}><ServiceTypeCreate /></RoleBasedGuard>) },
            { path: ':name/:id/edit', element: <ServiceTypeCreate /> },
       
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cardss', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: ( <RoleBasedGuard accessibleRoles={['admin']}><Calendar /></RoleBasedGuard>) },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element:  (    <GuestGuard>
        <Login />
      </GuestGuard>),
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/Category/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/Category/CategoryCreate')));
const OfferCreate = Loadable(lazy(() => import('../pages/dashboard/Offer/OfferCreate')));
const OfferList = Loadable(lazy(() => import('../pages/dashboard/Offer/OfferList')));

const StoreCreate = Loadable(lazy(() => import('../pages/dashboard/Store/StoreCreate')));
const StoreList = Loadable(lazy(() => import('../pages/dashboard/Store/StoreList')));

//Personal

const PersonnalCreate = Loadable(lazy(() => import('../pages/dashboard/Personnals/PersonnalCreate')));
const PersonnalList = Loadable(lazy(() => import('../pages/dashboard/Personnals/PersonnalList')));

//department

const DepartmentCreate = Loadable(lazy(() => import('../pages/dashboard/department/DepartmentCreate')));
const DepartmentList = Loadable(lazy(() => import('../pages/dashboard/department/DepartmentList')));


//Table booking
 
const BookingCreate = Loadable(lazy(() => import('../pages/dashboard/Booking/BookingCreate')));
const BookingList = Loadable(lazy(() => import('../pages/dashboard/Booking/BookingList')));
const SlotCreate = Loadable(lazy(() => import('../pages/dashboard/Booking/SlotCreate')));
const ListSlot = Loadable(lazy(() => import('../pages/dashboard/Booking/ListSlot')));
const ListSlotCalendar = Loadable(lazy(() => import('../pages/dashboard/Booking/ListSlotCalendar')));
//slot booking
const SBookingCreate = Loadable(lazy(() => import('../pages/dashboard/SlotBooking/BookingCreate')));
const SBookingList = Loadable(lazy(() => import('../pages/dashboard/SlotBooking/BookingList')));
const SSlotCreate = Loadable(lazy(() => import('../pages/dashboard/SlotBooking/SlotCreate')));
const SListSlot = Loadable(lazy(() => import('../pages/dashboard/SlotBooking/ListSlot')));
const SListSlotCalendar = Loadable(lazy(() => import('../pages/dashboard/SlotBooking/ListSlotCalendar')));

const TableCreate = Loadable(lazy(() => import('../pages/dashboard/Table/TableCreate')));
const TableList = Loadable(lazy(() => import('../pages/dashboard/Table/TableList')));


const ServiceTypeCreate = Loadable(lazy(() => import('../pages/dashboard/ServiceType/ServiceTypeCreate')));
const ServiceTypeList = Loadable(lazy(() => import('../pages/dashboard/ServiceType/ServiceTypeList')));

const ServiceCreate = Loadable(lazy(() => import('../pages/dashboard/Service/ServiceCreate')));
const ServiceList = Loadable(lazy(() => import('../pages/dashboard/Service/ServiceList')));

//store registration

const StoreAdminCreate = Loadable(lazy(() => import('../pages/dashboard/RegisteringStoreAdmin/RegisterAdmin')));
