import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  offers: [],
  personals: [],
  personalSingle: {},
  offer: {},
};

const slice = createSlice({
  name: 'personal',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getOffersSuccess(state, action) {
      state.isLoading = false;
      state.personals = action.payload;
    },

    deleteOffersSuccess(state, action) {
      // state.isLoading = false;
      // state.offers = action.payload;
      const { id } = action.payload;
      state.offers = state.offers.filter((offer) => offer.id !== id);
    },

    // GET PRODUCT
    getOfferSuccess(state, action) {
      state.isLoading = false;
      state.personalSingle = action.payload;
    },
    createOfferSuccess(state, action) {
      const newOffer = action.payload;
      state.isLoading = false;
      state.offers = [...state.offers, newOffer];
    },

    // CHECKOUT

    deleteOfferSuccess(state, action) {
      //     const { id } = action.payload;

      //  state.offers =  state.offers.filter((offer) => offer.id !== id);
      state.isLoading = false;
      state.offers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getOfferSuccess, getOffersSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function createPersonal(newOffer, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        console.log(newOffer);
        const response = await axiosAdmin.post('/employees', newOffer);
        callBack(false);
        console.log(response);
        // dispatch(slice.actions.createOfferSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPersonals(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get(`/employees/store/${storeId}`, {
          params: { storeId },
        });
        console.log(response);
        dispatch(slice.actions.getOffersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSinglePersonal(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.get(`/employees/${id}`);
      dispatch(slice.actions.getOfferSuccess(response.data));
      //  alert(response.data)
      console.log('getofffer', response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updatePersonal(id, updateOffer) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.patch(`/employees/${id}`, updateOffer);
      dispatch(slice.actions.getOffersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deletePersonal(offerId) {
  console.log(offerId);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.delete(`/employees/${offerId}`);

      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteManyPersonall(offerId) {
  console.log('first');
  // alert(offerId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.post('/employees/remove-many', offerId);
      console.log(response);
      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
